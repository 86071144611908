import { AxiosError } from 'axios';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  getDegradationPenaltyList,
  getDelayPenaltyList,
  postDelayOrDegradation,
  postRefund,
  postRegularization,
} from '../../api/reservation.api';
import { postUserCheckout, postUserRefund } from '../../api/user.api';
import { selectCollAndDeliveryPointID } from '../../auth/selectors/authSelectors';
import { TOAST_SEVERITY } from '../../components/Core/Toast/Toast';
import { useSnackbar } from '../../context/SnackbarContext';
import useGenerateFields, { Field } from '../../hook/useGenerateFields';
import { TRANSACTION_TYPES } from './CheckoutList.page';
import { useBooking } from './Reservation.layout';

function useTransactionBaseFields() {
  const { t } = useTranslation(['reservation/reservation']);
  const location = useLocation();

  const isUserCheckoutPage = useMemo(() => location.pathname.includes('usagers'), [location.pathname]);

  return useMemo<Field[]>(() => {
    const fields: Field[] = [
      {
        type: 'number',
        label: t('amount'),
        placeholder: '10,00',
        name: 'montant',
      },
      {
        type: 'radio-item',
        name: 'moyen',
        label: t('means'),
        options: [
          { value: 'cb', label: t('cb') },
          { value: 'cash', label: t('cash') },
          { value: 'cheque', label: t('cheque') },
        ],
      },
    ];

    if (isUserCheckoutPage) {
      fields.push({
        type: 'text',
        label: t('checkoutOperation'),
        placeholder: t('checkoutOperation'),
        name: 'operation',
      });
    }

    return fields;
  }, [t, isUserCheckoutPage]);
}

export function useRegularizationFormFields() {
  const { bookingId, userId } = useParams();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const { t } = useTranslation(['reservation/reservation', 'errors']);
  const transactionBaseFields = useTransactionBaseFields();
  const { control, handleSubmit, reset } = useForm();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const location = useLocation();

  const isUserCheckoutPage = useMemo(() => location.pathname.includes('usagers'), [location.pathname]);

  const { mutate: handlePostRegularizationBooking } = useMutation(
    ['regularization.post'],
    (data: Parameters<typeof postRegularization>[0]) => postRegularization(data),
    {
      onSuccess: () => {
        snackbar?.setAlert({ message: t('statement_submit'), severity: TOAST_SEVERITY.success });
        queryClient.invalidateQueries(['booking.checkout.list']);
        reset();
      },
    }
  );

  const { mutate: handlePostRegularizationUser } = useMutation(
    ['user.checkout.post'],
    //eslint-disable-next-line
    (data: any) => {
      return postUserCheckout(data);
    },
    {
      onSuccess: () => {
        snackbar?.setAlert({ message: t('form:global_update_success_message'), severity: TOAST_SEVERITY.success });
        queryClient.invalidateQueries(['user.checkout.list']);
        reset();
      },
    }
  );

  const onSubmit = useCallback(
    (onSuccess: () => void) =>
      handleSubmit((data) => {
        if (isUserCheckoutPage) {
          handlePostRegularizationUser({ id_coll, id_pdl, id_user: userId, ...data, rbt: false }, { onSuccess });
        } else {
          handlePostRegularizationBooking(
            //eslint-disable-next-line
            { id_coll, id_user: userId, id_resa: bookingId, ...(data as any) },
            {
              onSuccess,
            }
          );
        }
      }),
    [handleSubmit, handlePostRegularizationBooking, bookingId, userId, id_coll, handlePostRegularizationUser, id_pdl]
  );

  return { onSubmit, fieldElements: useGenerateFields({ control, fields: transactionBaseFields }) };
}

type UsePenaltyFormFieldsParams = {
  transactionType?: TRANSACTION_TYPES;
};

/**
 * For delays and degradations transactions
 */
export function usePenaltyFormFields({ transactionType }: UsePenaltyFormFieldsParams) {
  const { userId } = useParams();
  const { t } = useTranslation(['reservation/reservation', 'errors']);
  const transactionBaseFields = useTransactionBaseFields();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const { control, handleSubmit, watch, reset, setValue } = useForm();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const { data: options } = useQuery(
    ['options', { id_coll, transactionType }],
    async ({ queryKey }) => {
      const [, { transactionType, id_coll }] = queryKey as [
        string,
        { transactionType: TRANSACTION_TYPES; id_coll: number }
      ];

      if (transactionType === TRANSACTION_TYPES.degradation) {
        const response = await getDegradationPenaltyList({ ipp: 999, p: 1, id_coll });
        return response.data.result;
      } else {
        const response = await getDelayPenaltyList({ ipp: 999, p: 1, id_coll });
        return response.data.result;
      }
    },
    {
      suspense: false,
      refetchOnWindowFocus: false,
      staleTime: 400_000,
    }
  );

  const { mutate: handlePostPenalty } = useMutation(
    ['penalty.post'],
    (data: Parameters<typeof postDelayOrDegradation>[0]) => {
      return postDelayOrDegradation(data);
    },
    {
      onError: (error: AxiosError) => {
        snackbar?.setAlert({
          message: t('errors:'.concat(error.response?.data.code.replace('error.', ''))),
          severity: TOAST_SEVERITY.error,
        });
      },
      // onSuccess: (data, variables) => {
      onSuccess: () => {
        snackbar?.setAlert({ message: t('valid_form'), severity: TOAST_SEVERITY.success });
        queryClient.invalidateQueries(['booking.checkout.list']);
        reset();
        /*const resetValues: Record<string, null> = {
            montant: null,
            moyen: null,
            id_penalty: null,
          };
          if (variables.coeff) resetValues['coeff'] = null;
          reset(resetValues, { keepValues: false });*/
      },
    }
  );

  useEffect(() => {
    reset({}, { keepValues: false, keepDirtyValues: false });
  }, [transactionType, reset]);

  const optionSelected = watch('id_penalty');
  const coeff = watch('coeff');
  const amount = watch('montant');

  useEffect(() => {
    if (amount && coeff) {
      setValue('montant', Number(amount) * (Number(coeff) || 1));
    }
  }, [coeff, setValue]);

  useEffect(() => {
    if (optionSelected) {
      //eslint-disable-next-line
      const penaltySelected = (options as any[])?.find((option) =>
        'id_retard' in option ? option.id_retard === optionSelected : option.id_degradation === optionSelected
      );
      setValue('montant', (Number(penaltySelected.montant) * (Number(coeff) || 1)).toFixed(2));
    }
  }, [optionSelected, coeff]);

  const onSubmit = useCallback(
    (onSuccess: () => void) =>
      //eslint-disable-next-line
      handleSubmit((data: any) => {
        if (userId)
          handlePostPenalty(
            {
              id_coll,
              id_pdl,
              id_user: userId,
              t: transactionType === TRANSACTION_TYPES.delay ? 'retard' : 'degradation',
              moyen: data.moyen,
              id_t: data.id_penalty,
              coeff: transactionType === TRANSACTION_TYPES.delay ? Number(data.coeff || 1) : undefined,
            },
            { onSuccess }
          );
      }),
    [handleSubmit, handlePostPenalty, id_coll, id_pdl, userId, transactionType]
  );

  const fields = useMemo<Field[]>(() => {
    if (!options) return [];
    const fieldsArray: Field[] = [
      {
        type: 'select',
        options:
          options?.map((option) =>
            'id_degradation' in option
              ? { label: option.nom_degradation, value: option.id_degradation }
              : { label: option.nom_retard, value: option.id_retard }
          ) || [],
        name: 'id_penalty',
        label: transactionType === TRANSACTION_TYPES.delay ? t('delaysFieldLabel') : t('degradationFieldLabel'),
      },
      { ...transactionBaseFields[0], disabled: true },
    ];

    if (transactionType === TRANSACTION_TYPES.delay) {
      fieldsArray.push({
        type: 'number',
        name: 'coeff',
        label: t('coeff'),
        placeholder: '1',
      });
    }

    fieldsArray.push(transactionBaseFields[1]);

    return fieldsArray;
  }, [transactionBaseFields, t, transactionType, options]);

  return { onSubmit, fieldElements: useGenerateFields({ control, fields }) };
}

export function useRefundFormFields() {
  const { rawBooking } = useBooking();
  const { bookingId: id_resa, userId: id_user } = useParams();
  const { t } = useTranslation(['reservation/reservation', 'errors']);
  const { control, handleSubmit, reset } = useForm();
  const transactionBaseFields = useTransactionBaseFields();
  const queryClient = useQueryClient();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const snackbar = useSnackbar();
  const location = useLocation();

  const isUserCheckoutPage = useMemo(() => location.pathname.includes('usagers'), [location.pathname]);

  /*const { data: sogecommerceConfig } = useQuery(
    ['sogecommerceConfig', { id_coll }],
    ({ queryKey }) => {
      const [, params] = queryKey as [string, { id_coll: number }];
      return getSogecommerceConfig({ id_coll: params.id_coll });
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 400_000,
    }
  );*/

  const { mutate: handlePostRefund } = useMutation(
    ['refund.post'],
    (data: Parameters<typeof postRefund>[0]) => postRefund(data),
    {
      onError: (error: AxiosError) => {
        snackbar?.setAlert({
          message: t('errors:'.concat(error.response?.data.code.replace('error.', ''))),
          severity: TOAST_SEVERITY.error,
        });
      },
      onSuccess: () => {
        snackbar?.setAlert({ message: t('valid_form'), severity: TOAST_SEVERITY.success });
        queryClient.invalidateQueries(['booking.checkout.list']);
        reset();
      },
    }
  );

  //eslint-disable-next-line
  const { mutate: handlePostUserRefund } = useMutation(['refund.user'], (data: any) => postUserRefund(data), {
    onError: (error: AxiosError) => {
      snackbar?.setAlert({
        message: t('errors:'.concat(error.response?.data.code.replace('error.', ''))),
        severity: TOAST_SEVERITY.error,
      });
    },
    onSuccess: () => {
      snackbar?.setAlert({ message: t('valid_form'), severity: TOAST_SEVERITY.success });
      queryClient.invalidateQueries(['booking.checkout.list']);
      reset();
    },
  });

  const { mutate: postCheckout } = useMutation(
    ['user.checkout.post'],
    //eslint-disable-next-line
    (data: any) => {
      return postUserCheckout(data);
    },
    {
      onError: (error: AxiosError) => {
        snackbar?.setAlert({
          message: t('errors:'.concat(error.response?.data.code.replace('error.', ''))),
          severity: TOAST_SEVERITY.error,
        });
      },
      onSuccess: () => {
        reset();
        snackbar?.setAlert({ message: t('valid_form'), severity: TOAST_SEVERITY.success });
        queryClient.invalidateQueries(['user.checkout.list']);
      },
    }
  );

  const onSubmit = useCallback(
    (onSuccess: () => void) =>
      handleSubmit((data) => {
        if (isUserCheckoutPage) {
          postCheckout({ id_coll, id_pdl, id_user, ...data, rbt: true }, { onSuccess });
        } else {
          const { moyen, type, montant, operation } = data;
          if (moyen === 'CB internet' && type === 'autre') {
            snackbar?.setAlert({ message: t('errors:meanNotAvailable'), severity: TOAST_SEVERITY.error });
            return;
          } else if (moyen === 'CB internet' && (type === 'location' || type === 'depot_garantie')) {
            handlePostRefund(
              {
                id_resa: Number(id_resa),
                id_coll,
                amount: montant,
                type,
                operation,
              },
              { onSuccess }
            );
          } else {
            handlePostUserRefund(
              {
                ...data,
                id_coll,
                id_pdl,
                id_user,
                rbt: true,
              },
              { onSuccess }
            );
          }
        }
      }),
    [
      handleSubmit,
      id_user,
      id_coll,
      id_pdl,
      id_resa,
      isUserCheckoutPage,
      postCheckout,
      handlePostUserRefund,
      handlePostRefund,
    ]
  );

  const fields = useMemo<Field[]>(() => {
    const paymentMeanRadioItem = _.cloneDeep(transactionBaseFields[1]);
    // const paymentMeanRadioItem = { ...transactionBaseFields[1] };
    // if (sogecommerceConfig?.data.booking && !isUserCheckoutPage) {
    if (rawBooking?.isBookingOnlineCB && !isUserCheckoutPage) {
      //eslint-disable-next-line
      (paymentMeanRadioItem as any).options.push({ value: 'CB internet', label: t('cb_internet') });
    }

    return [
      transactionBaseFields[0],
      {
        type: 'radio-item',
        name: 'type',
        label: t('type'),
        options: [
          { value: 'depot_garantie', label: t('security_deposit_type') },
          { value: 'location', label: t('rental') },
          { value: 'autre', label: t('other') },
        ],
      },
      paymentMeanRadioItem,
      {
        type: 'text',
        name: 'operation',
        label: t('checkoutOperation'),
        placeholder: t('checkoutOperation'),
      },
    ];
  }, [t, transactionBaseFields, isUserCheckoutPage]);

  return { onSubmit, fieldElements: useGenerateFields({ control, fields }) };
}
