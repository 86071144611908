/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AxiosResponse } from 'axios';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFunction } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { getAllBookings } from '../../../api/booking.api';
import { RawGenericListReturn } from '../../../api/params/vouchers.api';
import { selectCollAndDeliveryPointID } from '../../../auth/selectors/authSelectors';
import { renderCell, Row, RowItem } from '../../../components/Core/Datagrid/Datagrid';
import { GridColDefinition } from '../../../components/Core/Datagrid/gridCols';
import MainSection from '../../../components/Core/MainSection/MainSection';
import Tag, { TagTypes } from '../../../components/Core/Tag/Tag';
import { ChevronIcon } from '../../../const/icons';
import { generatePath } from '../../../utils/router';
import { useThemeUtils } from '../../../utils/style';
import GenericList from '../../Parameters/Voucher/components/GenericList';
import { ListParams } from '../../park/BikeList/BikeList';

const mapTagTypeByBookingStatus = new Map<string, TagTypes>([
  ['file_attente', 'secondary'],
  ['attente', 'primary'],
  ['annulee', 'error'],
  ['validee', 'success'],
  ['terminee', 'plain-primary'],
]);

function useBookingColumns() {
  const { t } = useTranslation(['contracts/bookingList']);
  const { getColor } = useThemeUtils();

  const getColumnName = useCallback((name: string) => t(`columns.${name}`), [t]);

  return useMemo<GridColDefinition[]>(() => {
    return [
      { headerName: getColumnName('date_start'), type: 'date', field: 'date_start', sortable: true },
      { headerName: getColumnName('date_end'), type: 'date', field: 'date_end', sortable: true },
      {
        headerName: getColumnName('user_name'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <RowItem>
              {row.nom_user} {row.prenom_user}
            </RowItem>
          );
        },
        field: 'nomprenom',
        sortable: true,
      },
      { headerName: getColumnName('client'), type: 'string', field: 'nom', sortable: true },
      {
        headerName: getColumnName('booking'),
        type: 'custom',
        field: 'article',
        renderCell: ({ row }) => {
          return (
            <RowItem>
              {row.statut === 'file_attente' ? (
                <>
                  {_.replace(row.article, '(# en attente)', '')}
                  <br />
                  {`${t('position')} ${row.queue_position}`}
                </>
              ) : (
                row.article
              )}
            </RowItem>
          );
        },
      },
      {
        headerName: getColumnName('location'),
        type: 'custom',
        field: 'nom_pdl',
        renderCell: ({ row }) => {
          return <RowItem>{'nom_pdl' in row && row.id_pdl ? row.nom_pdl : row.nom_empl}</RowItem>;
        },
      },
      {
        headerName: getColumnName('status'),
        type: 'custom',
        renderCell: ({ row }) => {
          return (
            <div>
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              <Tag type={mapTagTypeByBookingStatus.get(row.statut)!}>{t(`options.${row.statut}`)}</Tag>
            </div>
          );
        },
        field: 'statut',
      },
      {
        headerName: '',
        field: '',
        type: 'custom',
        width: 40,
        renderCell: () => {
          return (
            <RowItem
              css={css`
                display: flex;
                justify-content: flex-end;
              `}>
              <ChevronIcon
                width={14}
                height={14}
                css={css`
                  color: ${getColor('gray.300')};
                `}
              />
            </RowItem>
          );
        },
      },
    ];
  }, [t, getColor, getColumnName]);
}

function ListReservations() {
  const { t } = useTranslation(['contracts/bookingList']);
  const { id_coll } = useSelector(selectCollAndDeliveryPointID);
  const columns = useBookingColumns();

  const fetchBookings: QueryFunction<AxiosResponse<RawGenericListReturn> | undefined> = ({ queryKey }) => {
    const [, requestParams, listParams] = queryKey as [string, { id_coll: number; id_user?: number }, ListParams];

    return getAllBookings({
      id_coll: requestParams.id_coll,
      ipp: listParams.itemPerPage,
      p: listParams.currentPage,
      'order[column]': listParams.order?.column,
      'order[dir]': listParams.order?.dir,
      ...listParams.filters,
    });
  };

  return (
    <MainSection className={'reset'}>
      <GenericList
        title={t('title')}
        translationNamespace={'contracts/bookingList'}
        RowComponent={({ row, columns, colsTemplate, key }) => (
          <Row
            key={key}
            colsTemplate={colsTemplate}
            to={generatePath('home.invoicing.reservationDetails', {
              params: { bookingId: row.id_reservation, userId: row.id_user },
            })}
            as={NavLink}>
            {columns.map((column, index) => renderCell(column, row, index))}
          </Row>
        )}
        queryKey={['booking.list', { id_coll }]}
        queryFn={fetchBookings}
        columns={columns}
      />
    </MainSection>
  );
}

export default ListReservations;
