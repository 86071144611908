import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import Link from '../Core/Link/Link';
import Step from '../Core/Step/Step';
import { BookedStep, RawBooking } from './BookingCard';

export function getCancelSteps(t: TFunction, rawBooking?: RawBooking, defaultSteps: JSX.Element[] = []) {
  return [
    ...defaultSteps,
    <BookedStep startDate={rawBooking?.date_reservation} lineColor={'danger'} />,
    <Step type={'danger'}>
      <Trans t={t} i18nKey={'canceledStep'} values={{ date: dayjs(rawBooking?.date_cancel).format('DD/MM/YYYY') }} />
    </Step>,
  ];
}

export function getCancelAfterValidateSteps(t: TFunction, token: string | null, rawBooking?: RawBooking) {
  return [
    <Step key={'validateStep'} lineColor={'danger'}>
      <Trans
        t={t}
        i18nKey={'validateStep'}
        values={{
          date: {
            date: dayjs(rawBooking?.date_valid).format('DD/MM/YYYY'),
            time: dayjs(rawBooking?.date_valid).format('HH:mm'),
          },
          location: `${rawBooking?.pdl_valid}`,
        }}
        components={{
          customLink: (
            <Link target={'_blank'} externalLink to={rawBooking?.contrat_link.concat(`&token=${token}`) || ''} />
          ),
        }}
      />
    </Step>,
    <Step type={'danger'}>
      <Trans t={t} i18nKey={'canceledStep'} values={{ date: dayjs(rawBooking?.date_cancel).format('DD/MM/YYYY') }} />
    </Step>,
  ];
}

export function getWaitingSteps(t: TFunction, rawBooking?: RawBooking, defaultSteps: JSX.Element[] = []) {
  return [
    ...defaultSteps,
    <Step lineColor={'yellow'}>
      <Trans
        t={t}
        i18nKey={'registeredWaitingListSince'}
        values={{
          date: dayjs(rawBooking?.date_reservation).format('DD/MM/YYYY'),
          time: dayjs(rawBooking?.date_reservation).format('HH:mm'),
        }}
      />
    </Step>,
    <Step type={'yellow'} lineColor={'secondary'}>
      <Trans t={t} i18nKey={'queuePosition'} values={{ queuePosition: rawBooking?.queue_position }} />
    </Step>,
    <Step type={'gray'}>
      <Trans t={t} i18nKey={'waitingNextStep'} />
    </Step>,
  ];
}

// export function getBookedSteps(t: TFunction, rawBooking?: RawBooking) {}
