import React, { ComponentPropsWithoutRef, InputHTMLAttributes, useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import { FinishBookingBodyForm, getCheckOutVerifs, postFinishBooking } from '../../../../api/booking.api';
import { selectCollAndDeliveryPointID } from '../../../../auth/selectors/authSelectors';
import ButtonWithIcon from '../../../../components/Core/Button/ButtonWithIcon';
import Card from '../../../../components/Core/Card/Card';
import Label from '../../../../components/Core/Label/Label';
import Modal from '../../../../components/Core/Modal/Modal';
import { generatePath } from '../../../../utils/router';
import styles from '../../bookingDetails.module.scss';
import { useBooking } from '../../Reservation.layout';
import SelectField from '../../../../components/Core/SelectField';
import { Option } from '../../../../hook/useBuildForm';
import { pdlListQuery } from '../../../../components/Sidebar/Sidebar';
import { mapDeliveryPointListToOptions } from '../../../../auth/services/authServices';
import { ARTICLE_TYPES } from './ManagementCard';
import radioStyles from './radioItem.module.scss';

const cx = classNames.bind(radioStyles);

export const RadioItem = React.forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    selected?: boolean;
    label: string | JSX.Element;
  }
>(({ selected = false, onChange, value, name, label, ...propsToForward }, ref) => {
  return (
    <label
      className={cx(radioStyles.FormControl, {
        [radioStyles.FormControl__selected]: selected,
        [radioStyles.Disabled]: propsToForward.disabled,
      })}>
      <input
        ref={ref}
        name={name}
        onChange={onChange}
        value={value}
        type={'radio'}
        className={radioStyles.Input}
        {...propsToForward}
      />
      {label}
    </label>
  );
});

type FinishModalProps = ComponentPropsWithoutRef<typeof Modal>;

function FinishModal({ setIsOpen }: FinishModalProps) {
  const { t } = useTranslation(['reservation/components/finish', 'common']);
  const { rawBooking } = useBooking();
  const { bookingId: id_resa, userId } = useParams();
  const { id_coll, id_pdl } = useSelector(selectCollAndDeliveryPointID);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: pdlListByCollIdResponse } = useQuery({
    ...pdlListQuery({ id_coll }),
    staleTime: 400_000,
    refetchOnWindowFocus: false,
  });

  const pdlOptions = useMemo(() => {
    return mapDeliveryPointListToOptions(pdlListByCollIdResponse?.data || []) || [];
  }, [pdlListByCollIdResponse]);

  const { control, handleSubmit } = useForm({ defaultValues: { pdlId: rawBooking?.id_pdl.toString(), status: null } });

  const { data: checkOutVerifs } = useQuery(
    ['booking.checkOutVerifs', { id_resa, id_coll }],
    ({ queryKey }) => {
      const [, queryParams] = queryKey as [string, { id_coll: number; id_resa: number }];
      return getCheckOutVerifs(queryParams);
    },
    {
      staleTime: 400_000,
      refetchOnWindowFocus: false,
      //eslint-disable-next-line
      onError: () => {},
    }
  );

  const { mutate: handleFinishBooking } = useMutation(
    ['booking.finish'],
    (bodyForm: FinishBookingBodyForm) => {
      return postFinishBooking(bodyForm);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['booking']);
        setIsOpen(false);
      },
    }
  );

  const onSubmit = useCallback(
    handleSubmit((data) => {
      const pdlId = rawBooking?.article === ARTICLE_TYPES.bike ? Number(data.pdlId) : id_pdl;

      if (id_resa) {
        if (checkOutVerifs?.data.length > 0) {
          navigate(
            generatePath('home.invoicing.checkOutVerifs', {
              params: { bookingId: id_resa, userId },
            }),
            {
              state: data,
            }
          );
          return;
        }
        //eslint-disable-next-line
        handleFinishBooking({ id_coll, id_pdl: pdlId, id_resa, t_etat: data.status! as string });
      }
    }),
    [rawBooking, id_coll, id_pdl, id_resa, checkOutVerifs, handleFinishBooking]
  );

  return (
    <Modal setIsOpen={setIsOpen} title={t('modal.title')}>
      <Controller
        name={'pdlId'}
        control={control}
        render={({ fieldState: { error }, field: { onChange, value } }) => (
          <SelectField
            className={'mb-4'}
            label={t('modal.pdlField.label')}
            placeholder={t('modal.pdlField.placeholder')}
            error={error}
            onChange={(newValue) => {
              onChange(newValue);
            }}
            options={pdlOptions}
            value={pdlOptions.find((option: Option) => option.value === value) || null}
          />
        )}
      />
      <Controller
        name={'status'}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div>
            <Label>
              {t('modal.returnStatusField.label', {
                context: rawBooking?.article === ARTICLE_TYPES.bike ? 'bike' : 'park',
              })}
            </Label>
            <div className={styles.finishModal__statusInput}>
              <RadioItem
                selected={value === 'pdl'}
                onChange={onChange}
                name={'radio'}
                value={'pdl'}
                label={
                  <Trans
                    t={t}
                    i18nKey={'modal.returnStatusField.options.available'}
                    context={rawBooking?.article === ARTICLE_TYPES.bike ? 'bike' : 'park'}
                  />
                }
              />
              <RadioItem
                selected={value === 'repare'}
                onChange={onChange}
                name={'radio'}
                value={'repare'}
                label={
                  <Trans
                    t={t}
                    i18nKey={'modal.returnStatusField.options.noAvailable'}
                    context={rawBooking?.article === ARTICLE_TYPES.bike ? 'bike' : 'park'}
                  />
                }
              />
              {rawBooking?.article === ARTICLE_TYPES.bike && (
                <RadioItem
                  selected={value === 'vole'}
                  onChange={onChange}
                  name={'radio'}
                  value={'vole'}
                  label={<Trans t={t} i18nKey={'modal.returnStatusField.options.stolen'} />}
                />
              )}
            </div>
          </div>
        )}
      />
      <div className={styles.assignModal__footer}>
        <ButtonWithIcon icon={'CrossWithoutCircle'} color={'secondary'} onClick={() => setIsOpen(false)}>
          {t('common:cancel')}
        </ButtonWithIcon>
        <ButtonWithIcon icon={'CheckWithoutCircle'} onClick={onSubmit}>
          {t('modal.cta')}
        </ButtonWithIcon>
      </div>
    </Modal>
  );
}

function FinishCard() {
  const { rawBooking } = useBooking();
  const { t } = useTranslation(['reservation/components/finish']);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card
      className={styles.finishCard}
      content={
        <>
          <p className={styles.finishCard__text}>
            {t('card.description', { context: rawBooking?.article === ARTICLE_TYPES.bike ? 'bike' : 'park' })}
          </p>
          <ButtonWithIcon
            onClick={() => setIsOpen(true)}
            className={styles.finishCard__button}
            icon={'CheckWithoutCircle'}>
            {t('card.cta')}
          </ButtonWithIcon>

          <React.Suspense fallback={<></>}>{isOpen && <FinishModal setIsOpen={setIsOpen} />}</React.Suspense>
        </>
      }
    />
  );
}

export default FinishCard;
